.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

article.specialist {
  cursor: pointer;
}

article.specialist .specialist__image {
  object-fit: cover;
  height: 250px;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

@media only screen and (max-width: 900px) {
  article.specialist {
    display: flex;
    flex-direction: column;
  }

  article.specialist .specialist__image {
    object-position: 60% 0;
    height: 400px;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: unset;
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 4;
}

.footer-link:hover {
  color: #ffff71;
  font-weight: bold;
}

.header-link {
  color: white;
}
.header-link:hover {
  color: #ffff71;
  font-weight: bold;
}

.cta-buy-tickets {
  background-color: #ffff71;
  color: #250048;
  transition: 0.3s;
}

.cta-buy-tickets:hover {
  background-color: #ff6e6e;
  color: #ffff71;
  font-weight: bold;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vertical-timeline-element-content {
  background-color: #eceef1;
}

.vertical-timeline-element-content h4 {
  font-weight: bold;
}

.vertical-timeline::before {
  background: #eceef1;
}

.highligh-promo {
  background-color: #ff6e6e;
  padding: 4px;
  font-weight: bold;
  color: #250048;
  border-radius: 4px;
}
