.swiper-pagination-bullet-active {
  background-color: #250048;
}

.sponsor-container {
  width: 100%;
}

.sponsor-container svg {
  width: 100%;
  min-height: 36px;
}
