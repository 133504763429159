.container-vidalia {
  display: flex;
}
.img-vidalia {
  width: 50px;
  border-radius: 9999px;
  margin-top: -18px;
  margin-left: 10px;
  opacity: 0.85;
}

@media screen and (max-width: 768px) {
  .img-vidalia {
    width: 80px;
    border-radius: 9999px;
    margin-top: -18px;
    margin-left: 10px;
    opacity: 0.85;
  }
}
