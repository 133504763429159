@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'PPNeueMachina';
  src: local('PPNeueMachina'), url(../public/fonts/PPNeueMachina.woff) format('woff');
}

body {
  margin: 0;
  font-family: PPNeueMachina, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.experience--title:after {
  background-color: #ff6e6e;
  content: '';
  display: block;
  height: 2px;
  margin: 0.66667rem 0 0;
  width: 5rem;
  margin-left: auto;
  margin-right: auto;
}

html {
  scroll-behavior: smooth;
}
